var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-checkbox', {
    class: "custom-control-warning CustomCheckbox\n  ".concat(_vm.isDisable ? 'label_disabled' : '', "\n   "),
    attrs: {
      "disabled": "",
      "switch": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary.window",
      modifiers: {
        "hover": true,
        "v-primary": true,
        "window": true
      }
    }],
    staticClass: "text-body font-weight-bolder",
    attrs: {
      "title": "Chỉ hỗ trợ tìm kiếm giá tách chặng"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.isSplitTripFare')) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }