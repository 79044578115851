import { render, staticRenderFns } from "./SearchSplitTripFareSwitch.vue?vue&type=template&id=81cd3d5e&scoped=true"
import script from "./SearchSplitTripFareSwitch.vue?vue&type=script&lang=js"
export * from "./SearchSplitTripFareSwitch.vue?vue&type=script&lang=js"
import style0 from "./SearchSplitTripFareSwitch.vue?vue&type=style&index=0&id=81cd3d5e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81cd3d5e",
  null
  
)

export default component.exports