<template>
  <b-form-checkbox
    v-model="value"
    disabled
    switch
    :class="`custom-control-warning CustomCheckbox
    ${ isDisable ? 'label_disabled' : '' }
     `"
  >
    <!-- @change="(val) => handleUpdate(val)" -->
    <span
      v-b-tooltip.hover.v-primary.window
      title="Chỉ hỗ trợ tìm kiếm giá tách chặng"
      class="text-body font-weight-bolder"
    >
      {{ $t('flight.isSplitTripFare') }}
    </span>
  </b-form-checkbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const value = ref(true)

    // watch(
    //   () => props.numberOfStop,
    //   val => {
    //     if (val === 0) {
    //       isDirectFlightOnly.value = true
    //     } else {
    //       isDirectFlightOnly.value = false
    //     }
    //   },
    //   { immediate: true },
    // )

    // const handleUpdate = val => {
    //   if (val === false) {
    //     emit('update:numberOfStop', 99)
    //   } else {
    //     emit('update:numberOfStop', 0)
    //   }
    // }

    return {
      value,
      // handleUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.CustomCheckbox::v-deep {
  display: flex;
  align-items: center;
  .custom-control-label {
    margin: 4px 0 !important;

    span {
      font-size: 1.2rem;
    }
  }

  &.label_disabled {
    .custom-control-label {
      span {
        color: #999999 !important;
      }
    }
  }
}
</style>
